import { page } from '$app/stores';
import dayjs from 'dayjs';
import { writable, derived, get } from 'svelte/store';
import trackingMethods from './legacy_store/tracking/methods.js';

function default_selected_debtor() {
	const session = sessionStorage.getItem('CURRENT_USER_debtor');
	if (session) return JSON.parse(session);
	const local = localStorage.getItem('DEFAULT_USER_debtor');
	if (local) {
		sessionStorage.setItem('CURRENT_USER_debtor', local);
		return JSON.parse(local);
	}
	return null;
}

export const settings = writable();
export const selected_debtor = writable(default_selected_debtor());

export const ng_frame = writable();
export const ng_app_loaded = writable(false);
export const show_ng_frame = writable(false);
export const show_company_references = writable(false);
export const making_booking = writable(false);

export function reset_iframe() {
	get(ng_frame)?.remove();
	ng_app_loaded.set(false);
	show_ng_frame.set(false);
}

export let userInfo = writable(undefined);
export let isAnonymousUser = writable(false);
export let usersName = writable('');
export let usersEmail = writable('');
export let usersInitials = writable('');
export let userDebtor = writable(JSON.parse(sessionStorage.getItem('CURRENT_USER_debtor')));

export let UserIsConsultant = derived(
	userInfo,
	($userInfo) => $userInfo && $userInfo.Role === 'Consultant'
);

export function resetUser() {
	userInfo.set(undefined);
	isAnonymousUser.set(false);
	usersName.set('');
	usersEmail.set('');
	usersInitials.set('');
	userDebtor.set(null);
}

export const ng_route_active = derived([page, show_ng_frame], ([$page, $show_ng_frame]) => {
	return $show_ng_frame || $page.route.id === '/(authenticated)/[...ng_route]';
});

export let show_hotel_info_modal = writable(false);
export let show_duplicate_bookings = writable(false);

export let show_error_notification = writable(false);

//***************** Start International Flights *****************//

function initial_flight(default_time) {
	return {
		date: dayjs().format('DD/MM/YYYY'),
		time: default_time ? default_time : '7:00AM',
		origin: undefined,
		destination: undefined
	};
}

export let initial_flights = () => [initial_flight(), initial_flight('5:00PM')];

export function addFlight(prev = initial_flight()) {
	prev.time = '7:00AM';
	return prev;
}

export const edit_traveller = writable();
export const guest_traveller_dialog_index = writable(null);
export const itinerary = writable();
export const travellers = writable([]);
export const flights = writable([]);
export const search_results_untouched = writable([]);
export const search_results = writable([]);
export const slice_result_by = writable(20);
export const fare_buckets = writable([]);
export const selected_flights = writable({});
export const selected_fare = writable();
export const selected_bucket = writable();
export const bucket_from_additional_selected = writable();
export const filters = writable([]);
export const selected_airlines = writable([]);
export const selected_stops = writable([]);
export const selected_fare_price_range = writable([]);
export const selected_fare_classes = writable([]);
export const sort_by = writable(null);
export const logo_dictionary = writable({});
export const selected_out_of_policy_reason = writable(null);

function filter_by_selected_fare(result, selected_fare) {
	return !(selected_fare?.index === result.index);
}

function filter_by_airline(flight, selected_airlines) {
	if (selected_airlines.length === 0) return true;
	return selected_airlines.includes(flight.validating_carrier.name);
}

function filter_by_selected_flights(flights, selected_flights) {
	let selected_length = Object.keys(selected_flights).length;
	let matches = 0;
	Object.keys(selected_flights).forEach((flight) => {
		if (flights.find((f) => f.id === flight)) matches++;
	});

	return selected_length === matches;
}

function filter_by_number_of_stops(flights, selected_stops) {
	if (selected_stops.length === 0) return true;
	let spilt_flights = [];
	let leg_index = 0;
	flights.forEach(flight => {
		let leg = spilt_flights[leg_index];
		if (leg) { 
			spilt_flights[leg_index] = [...leg, flight]
		}
		else spilt_flights[leg_index] = [flight];
		if (flight.destination.leg_boundary) leg_index += 1;
	});

	if (selected_stops.includes('2')) {
		return spilt_flights.every(l => l.length < 4); 
	}

	if (selected_stops.includes('1')) {
		return spilt_flights.every(l => l.length < 3); 
	}

	if (selected_stops.includes('nonstop')) {
		return spilt_flights.every(l => l.length === 1);
	}

}

function filter_by_price_range(buckets, range) {
	if (range.length === 0) return true;
	const _buckets = buckets.filter((bucket) => {
		let fares = bucket.Fares.filter((fare) => {
			return fare.BaseCurrency.Amount >= range[0] && fare.BaseCurrency.Amount <= range[1];
		});
		return fares.length > 0;
	});
	return _buckets.length > 0;
}

function filter_by_carrier_class(buckets, fare_classes) {
	if (fare_classes.length === 0) return true;
	return buckets.find((b) => fare_classes.includes(b.Name) && b.Fares.length > 0);
}

export function filter_results(
	results,
	selected_fare,
	selected_flights,
	selected_airlines,
	selected_stops,
	selected_fare_price_range,
	selected_fare_classes
) {
	return results.filter((r, i) => {
		let keep = true;

		// fares
		if (keep) keep = filter_by_selected_fare(r, selected_fare);

		// flights
		if (keep) keep = filter_by_selected_flights(r.flights, selected_flights);
		if (keep) keep = !!r.flights.find((flight, i) => filter_by_airline(flight, selected_airlines));
		if (keep) keep = filter_by_number_of_stops(r.flights, selected_stops);

		// buckets
		if (keep) keep = filter_by_price_range(r.FareBuckets, selected_fare_price_range);
		if (keep) keep = filter_by_carrier_class(r.FareBuckets, selected_fare_classes);

		return keep;
	});
}

function sort_by_cheapest(results) {
	return results.sort((a, b) => {
		const fare_classes = get(selected_fare_classes);

		if (fare_classes.length > 0) {
			a.FareBuckets = a.FareBuckets.filter((b) => fare_classes.includes(b.Name));
			b.FareBuckets = b.FareBuckets.filter((b) => fare_classes.includes(b.Name));
		}
		const a_prices = a.FareBuckets.flatMap((bucket) =>
			bucket.Fares.map((fare) => fare.BaseCurrency.Amount)
		);
		const a_lowest = Math.min(...a_prices);

		const b_prices = b.FareBuckets.flatMap((bucket) =>
			bucket.Fares.map((fare) => fare.BaseCurrency.Amount)
		);
		const b_lowest = Math.min(...b_prices);

		return a_lowest - b_lowest;
	});
}

function sort_by_legs(results) {
	return results.sort((a, b) => {
		return a.flights.length - b.flights.length;
	});
}

export const derived_flights = derived(
	[
		search_results,
		selected_flights,
		selected_fare,
		selected_airlines,
		selected_stops,
		selected_fare_price_range,
		selected_fare_classes,
		sort_by
	],
	([
		$search_results,
		$selected_flights,
		$selected_fare,
		$selected_airlines,
		$selected_stops,
		$selected_fare_price_range,
		$selected_fare_classes,
		$sort_by
	]) => {
		slice_result_by.set(20);
		let results = filter_results(
			$search_results,
			$selected_fare,
			$selected_flights,
			$selected_airlines,
			$selected_stops,
			$selected_fare_price_range,
			$selected_fare_classes
		);

		if ($sort_by === 'cheapest') results = sort_by_cheapest(results);
		if ($sort_by === 'shortest') results = sort_by_legs(results);

		return results;
	}
);

export const sliced_flights = derived(
	[derived_flights, slice_result_by],
	([$derived_flights, $slice_result_by]) => {
		return $derived_flights.slice(0, $slice_result_by) || [];
	}
);

export function reset_search_form() {
	search_results_untouched.set([]);
	search_results.set([]);
	slice_result_by.set(20);
	fare_buckets.set([]);
	selected_flights.set({});
	selected_fare.set();
	selected_bucket.set();
	bucket_from_additional_selected.set();
	filters.set([]);
	selected_airlines.set([]);
	selected_stops.set([]);
	selected_fare_price_range.set([]);
	selected_fare_classes.set([]);
	sort_by.set(null);
	travellers.set([]);
	flights.set(initial_flights);
}

export function reset_store() {
	trackingMethods.RESET();
	reset_search_form();
	settings.set(null);
	selected_debtor.set(null);
}

//***************** End International Flights *****************//

export let show_fare_rules_dialog = writable(false);